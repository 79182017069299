export default {
    PRODWS_URL: process.env.PRODWS_AFREESPACE_URL || "https://accountws.us.afreespace.com",
    TIMWS_URL: process.env.TIMWS_AFREESPACE_URL || "https://timws.us.afreespace.com",
    SPOTWS_URL: process.env.SPOT_AFREESPACE_URL || "https://spotws.us.afreespace.com",
    ELECTRIC_URL: process.env.ELECTRIC_IMP_URL || "https://imp-agent-proxy.us.afreespace.com",
    UPLOAD_URL: process.env.UPLOAD_AFREESPACE_URL || "https://tim-tools.us.afreespace.com",
    TIMP_PRODUCT_ID: process.env.TIMPLUS_PRODUCT_ID || "62d3da73-debf-8660-5fb4-f5d6c646358f",
    BAT_TIM_PRODUCT_ID: process.env.BAT_TIM_PRODUCT_ID || "cb1a34bf-e4ea-a74b-d496-37c1183ffa97",
    ELECTRIC_AGENT_URL: process.env.ELECTRIC_IMP_AGENT_URL || "https://agent.electricimp.com",
    LOGIN_BASE_URL: process.env.LOGIN_AFRESSPACE_URL || "https://login.us.afreespace.com",
    PROD_REFERRER: process.env.PROD_REFERRER_URL || "https://dot.afreespace.com",
    DEV_REFERRER: process.env.DEV_REFERRER_URL || "https://dot-dev.us.afreespace.com",
    LOCALHOST_REFERRER: process.env.LOCALHOST_REFERRER_URL || "https://localhost.afreespace.com:8443",
    WEB_SOCKET_URL: process.env.WEB_SOCKET_URL || 'wss://spot-wss.us.afreespace.com',
}