
import AuthService from "../Auth/auth";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import config from "../config";


function Authenticate() {
    var key = localStorage.getItem('spaceio-key')
    const params = new URLSearchParams(window.location.search)
    const navigate = useNavigate();
    const [authCode, setAuthCode] = useState(params.has('xauthcode') ? params.get('xauthcode') : null)
    useEffect(() => {
        async function fetchToken() {
            const result = await AuthService.getAuthToken(authCode);
            navigate('/selectProduct')
        }
        fetchToken();
    }, [authCode])

    if (!params.has('xauthcode')) {
        //window.location.href = `${config.LOGIN_BASE_URL}/v2?referrer=${config.PROD_REFERRER}`;
        window.location.href = `${config.LOGIN_BASE_URL}/v2?referrer=${config.DEV_REFERRER}`;
        //window.location.href = `${config.LOGIN_BASE_URL}/v2?referrer=${config.LOCALHOST_REFERRER}`;

    }
}

export default Authenticate;