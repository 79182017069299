
import { useState, useEffect, useRef } from "react";
import "./rectangleCardFLO.css";
import capacityIcon from "../../assets/images/capacity.svg"

function RectangleCardFLO({
    deviceName,
    deviceId = "000",
    markerId = "000",
    productId,
    // capacity = "000",
    floMetaDataStatus = undefined,
    deviceStatus,
    downbarClick,
    onClick,
    tabInd,
    onResetClickCard,
    handleCalibrateButtonClickedForSetUp,
    handleRectangleCardButtonClickForZone,
    handleRectangleCardButtonClickForTest }
) {
    const [calibrationStatusText, setcalibrationStatusText] = useState({});
    const ref = useRef();

    useEffect(() => {
        function checkStatus(status) {
            if (status === 0) {
                setcalibrationStatusText({ text: "Set-up", background: "transparent", type: "button" })
            }
            else if (status === 1) {
                setcalibrationStatusText({ text: "Set-up done", background: "#29CF81", type: "span" })
            }
            else if (status === 2) {
                setcalibrationStatusText({ text: "To be tested", background: "#F65162", type: "span" })
            }
            else if (status === 3) {
                setcalibrationStatusText({ text: "Test Passed", background: "#29CF81", type: "span" })
            }
            else {
                setcalibrationStatusText({ text: "Admin Setup Pending", background: "#F65162", type: "span" })
            }
        }

        if (deviceStatus === "Offline") {
            setcalibrationStatusText({ text: "Offline", background: "grey", type: "span" })
        }
        else {
            checkStatus(floMetaDataStatus);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceStatus])

    useEffect(() => {
        if (ref.current && tabInd) ref.current.blur();
    }, [tabInd])

    function productTypeFromProductID(productId) {
        switch (productId) {
          case "6875f0bf-157d-332e-883f-70e58401dd14":
            return "TIM";
          case "62d3da73-debf-8660-5fb4-f5d6c646358f":
            return "TIM+";
          case "2d5ef6ed-051c-0bb9-70a4-718d31215e0e":
            return "FLO";
          case "cb1a34bf-e4ea-a74b-d496-37c1183ffa97":
            return "BAT-TIM";
          case "4b357a17-cc2d-5d63-116f-a539a78a2802":
            return "POE TIM+";
          case "6a36e934-426c-025f-a025-89bed058974d":
            return "POE TIM";
          case "85939db1-afba-b79e-b424-2e295ec2a9b5":
            return "POE FLO";
          default:
            return "--"; // Default value when deviceId doesn't match any case
        }
      }

    return (
        <div className="rectangle-card-FLO-body p-3 my-3"
            onClick={downbarClick}
            style={{ cursor: "pointer" }}>
            <div className="rectangle-card-FLO-body-top">
                <span className="rectangle-card-FLO-body-top-content1">{deviceName}</span>
                <div>
                    {calibrationStatusText.type === "button" ?
                        <button ref={ref} tabIndex={tabInd ? -1 : 0} className="rectangle-card-FLO-body-top-content" onClick={handleCalibrateButtonClickedForSetUp}>
                            {calibrationStatusText.text}
                        </button> :
                        <span className="rectangle-card-FLO-body-top-content1-setUp-done" style={{ background: calibrationStatusText.background }}>
                            {calibrationStatusText.text}
                        </span>
                    }
                    {/* {retest ? <button onClick={retest} ref={ref} tabIndex={tabInd ? -1 : 0} className="rectangle-card-status-for-space-reset-button" style={{ marginLeft: "0.5rem" }}>Retest</button> : null} */}
                </div>
            </div>

            <div className="rectangle-card-FLO-body-bottom">
                <span style={{fontWeight:"700"}}>Product Type: {productTypeFromProductID(productId)}</span>
                <span>Device ID:   {deviceId}</span>
                <span>Marker ID:  {markerId}</span>
            </div>
            <div >
                {calibrationStatusText.text !== "Test Passed" ?
                    calibrationStatusText.text === "To be tested" ?
                        <button className="rectangle-card-FLO-body-top-content2-dark"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleRectangleCardButtonClickForTest();
                            }}>
                            Test FLO
                        </button>
                        : calibrationStatusText.text === "Set-up done" ?
                            <button className="rectangle-card-FLO-body-top-content2-dark"
                                onClick={handleRectangleCardButtonClickForZone}>
                                Zone Setup
                            </button>
                            : <></>
                    : <></>
                }
                {calibrationStatusText.text === "Set-up done" || calibrationStatusText.text === "To be tested" ?
                    <button onClick={(e) => { e.stopPropagation(); onResetClickCard() }}
                        onKeyDown={(e) => { if (e.key === "Enter" || e.key === ' ') { e.stopPropagation(); onResetClickCard() } }}
                        className="rectangle-card-FLO-body-top-content2"
                        style={{ marginTop: "0.5rem" }}>
                        Reset
                    </button>
                    : null}

                {/* <span className="rectangle-card-space-capacity">
                    <img src={capacityIcon} alt="" style={{ margin: "0 2px 2px 2px" }}></img>
                     <span className="rectangle-card-FLO-capacity">{capacity} capacity</span>
                </span> */}
            </div>

        </div>);
}

export default RectangleCardFLO;